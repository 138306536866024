
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as abuseReportsnqWS8Bl4zcMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93YL9aLPzMT2Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexx7TNfTvOnPMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/index.vue?macro=true";
import { default as moderationwsZTz7LX17Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/moderation.vue?macro=true";
import { default as pendingBansP8Z7IS32L6Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/pendingBans.vue?macro=true";
import { default as profilesj6hsvmSpUkMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/profiles.vue?macro=true";
import { default as expensesX3iqLqa3jzMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexaOAFvRbDFJMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewPm9JgIt1mXMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingm4nPGQia0tMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingcCrok4IyYQMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/translations/missing.vue?macro=true";
import { default as users76IpYsXr4lMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/users.vue?macro=true";
import { default as apivI30LdGdFpMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/api.vue?macro=true";
import { default as _91slug_93PQ9po1zjNGMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/blog/[slug].vue?macro=true";
import { default as indexAEtXpvZzssMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93XyTAStV0H7Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93ymA5FxkqQuMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminDeDwqoOv2wMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/census/admin.vue?macro=true";
import { default as indexqrlUJxIIMyMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/census/index.vue?macro=true";
import { default as contact1iEDhyByvjMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/contact.vue?macro=true";
import { default as designeK58SAmCS3Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/design.vue?macro=true";
import { default as english6Tst3btk8VMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/english.vue?macro=true";
import { default as faqKbX9kI6OY8Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/faq.vue?macro=true";
import { default as inclusivemdx6MXF8jlMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/inclusive.vue?macro=true";
import { default as indexE3jwbeEvgoMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/index.vue?macro=true";
import { default as license1smEuienQAMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/license.vue?macro=true";
import { default as academiceCH8JrKsbqMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/links/academic.vue?macro=true";
import { default as indexfjROzhaXCaMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/links/index.vue?macro=true";
import { default as mediax5a50yhtoCMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/links/media.vue?macro=true";
import { default as translinguisticshvmjThCmGhMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/links/translinguistics.vue?macro=true";
import { default as zine8qYEJe3P6NMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/links/zine.vue?macro=true";
import { default as namesIZRRiUwYkqMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/names.vue?macro=true";
import { default as indexRqLhrdUDeNMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/nouns/index.vue?macro=true";
import { default as templatesyMM99gHXluMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/nouns/templates.vue?macro=true";
import { default as peoplenaXbPGGpNIMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/people.vue?macro=true";
import { default as privacy4pbItTbFb1Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/privacy.vue?macro=true";
import { default as _91username_93FTUokEsvKRMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_933a7ikvEqD0Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/profile/card-[username].vue?macro=true";
import { default as editoriswZIcFb7HMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/profile/editor.vue?macro=true";
import { default as anyQjhEJlsjghMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/any.vue?macro=true";
import { default as ask0rB10Wqaj5Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/ask.vue?macro=true";
import { default as avoidingt41TN8Rj28Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/avoiding.vue?macro=true";
import { default as indexDGWFRPuTmAMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/index.vue?macro=true";
import { default as mirrora046xzSyKrMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/mirror.vue?macro=true";
import { default as pronoun8WbOonXxlJMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesxIHbN6udBaMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/sources.vue?macro=true";
import { default as teamjTBUnIIpSIMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/team.vue?macro=true";
import { default as terminologyoWP978Upr1Meta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/terminology.vue?macro=true";
import { default as termshF4VxoWkgXMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/terms.vue?macro=true";
import { default as user3a5feWARliMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/user.vue?macro=true";
import { default as workshopsZaR8qW66GoMeta } from "/home/admin/www/test.pronouns.page/release/20241229134636/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsnqWS8Bl4zcMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansP8Z7IS32L6Meta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apivI30LdGdFpMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93PQ9po1zjNGMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexAEtXpvZzssMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93XyTAStV0H7Meta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93ymA5FxkqQuMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminDeDwqoOv2wMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexqrlUJxIIMyMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact1iEDhyByvjMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: english6Tst3btk8VMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqKbX9kI6OY8Meta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivemdx6MXF8jlMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academiceCH8JrKsbqMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexfjROzhaXCaMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediax5a50yhtoCMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticshvmjThCmGhMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zine8qYEJe3P6NMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesIZRRiUwYkqMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexRqLhrdUDeNMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesyMM99gHXluMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peoplenaXbPGGpNIMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy4pbItTbFb1Meta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93FTUokEsvKRMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_933a7ikvEqD0Meta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editoriswZIcFb7HMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyQjhEJlsjghMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: ask0rB10Wqaj5Meta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingt41TN8Rj28Meta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexDGWFRPuTmAMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrora046xzSyKrMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronoun8WbOonXxlJMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesxIHbN6udBaMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamjTBUnIIpSIMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyoWP978Upr1Meta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termshF4VxoWkgXMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: user3a5feWARliMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsZaR8qW66GoMeta || {},
    component: () => import("/home/admin/www/test.pronouns.page/release/20241229134636/pages/workshops.vue")
  }
]