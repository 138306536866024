import payload_plugin_1JPllnPF1I from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13__yr4pdc3rvc52nswtsx5h7juyee/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_wyI9R71mb9 from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_kqwruk5qm2v6bse7fsu3oalxba/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_94M9p2yw9N from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_kqwruk5qm2v6bse7fsu3oalxba/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zBIMUpAhqu from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_kqwruk5qm2v6bse7fsu3oalxba/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_wwKwlyWxyd from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_kqwruk5qm2v6bse7fsu3oalxba/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_VpPXjqcVWQ from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_kqwruk5qm2v6bse7fsu3oalxba/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_EGBx6Gp2TZ from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_kqwruk5qm2v6bse7fsu3oalxba/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kZ2xYF8hH1 from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_kqwruk5qm2v6bse7fsu3oalxba/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_76PV314L1N from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13__yr4pdc3rvc52nswtsx5h7juyee/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/admin/www/test.pronouns.page/release/20241229134636/.nuxt/components.plugin.mjs";
import prefetch_client_TGpBQtxzmR from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_kqwruk5qm2v6bse7fsu3oalxba/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/admin/www/test.pronouns.page/release/20241229134636/.nuxt/pwa-icons-plugin.ts";
import pwa_client_uaLwyGybQ8 from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.22.2_vite@6.0.6_@types+node_bgw5ng7g6iksuywldycu55447q/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_qMOagP1dLP from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.22.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import auth_vT9JWWT9pN from "/home/admin/www/test.pronouns.page/release/20241229134636/plugins/auth.ts";
import browserDetect_3Y4e6ym8wL from "/home/admin/www/test.pronouns.page/release/20241229134636/plugins/browserDetect.ts";
import globals_qEnHRCYwqu from "/home/admin/www/test.pronouns.page/release/20241229134636/plugins/globals.ts";
import polyfill_client_aOiFD9Uk19 from "/home/admin/www/test.pronouns.page/release/20241229134636/plugins/polyfill.client.ts";
import sentry_client_shVUlIjFLk from "/home/admin/www/test.pronouns.page/release/20241229134636/plugins/sentry.client.ts";
import track_client_qrg1Mffzr3 from "/home/admin/www/test.pronouns.page/release/20241229134636/plugins/track.client.ts";
import plugin_auto_pageviews_client_6CD0kc9SLO from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.22.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  payload_plugin_1JPllnPF1I,
  revive_payload_client_wyI9R71mb9,
  unhead_94M9p2yw9N,
  router_zBIMUpAhqu,
  payload_client_wwKwlyWxyd,
  navigation_repaint_client_VpPXjqcVWQ,
  check_outdated_build_client_EGBx6Gp2TZ,
  chunk_reload_client_kZ2xYF8hH1,
  plugin_vue3_76PV314L1N,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TGpBQtxzmR,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_uaLwyGybQ8,
  plugin_client_qMOagP1dLP,
  auth_vT9JWWT9pN,
  browserDetect_3Y4e6ym8wL,
  globals_qEnHRCYwqu,
  polyfill_client_aOiFD9Uk19,
  sentry_client_shVUlIjFLk,
  track_client_qrg1Mffzr3,
  plugin_auto_pageviews_client_6CD0kc9SLO
]