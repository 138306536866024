import routerOptions0 from "/home/admin/www/test.pronouns.page/release/20241229134636/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_kqwruk5qm2v6bse7fsu3oalxba/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/admin/www/test.pronouns.page/release/20241229134636/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}