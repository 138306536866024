<template>
    <Page v-if="config.links.enabled">
        <LinksNav />

        <Loading v-if="blogShown" :value="posts">
            <template #header>
                <h2 class="mb-3">
                    <Icon v="pen-nib" />
                    <T>links.blog</T>
                </h2>
            </template>
            <BlogEntriesList :posts="posts!" details />
        </Loading>
        <Endorsements />
        <AdPlaceholder :phkey="['content-0', 'content-mobile-0']" />
        <Links />
        <AcademicLinks v-if="!config.links.split && Object.keys(config.links.academic).length > 0" />
        <Media v-if="config.links.mediaGuests.length + config.links.mediaMentions.length > 0 && (!config.links.split || config.links.mediaGuests.length + config.links.mediaMentions.length <= 2)" />
        <AdPlaceholder :phkey="['content-1', 'content-mobile-1']" />
        <Recommended />
        <Socials />
        <LanguageVersions />
        <AdPlaceholder :phkey="['content-2', 'content-mobile-2']" />
        <section>
            <h2 class="mb-3">
                <Icon v="home-heart" />
                <T>footer.sibling</T>
            </h2>
            <Siblings />
        </section>
        <Support />
        <section>
            <Share :title="$t('links.headerLong')" />
        </section>
    </Page>
</template>

<script lang="ts">
import { useNuxtApp } from 'nuxt/app';
import { defineComponent } from 'vue';

import useConfig from '~/composables/useConfig.ts';
import useSimpleHead from '~/composables/useSimpleHead.ts';

export default defineComponent({
    setup() {
        definePageMeta({
            translatedPaths: (config) => translatedPathByConfigModule(config.links),
        });

        const { $translator: translator } = useNuxtApp();
        const config = useConfig();
        useSimpleHead({
            title: translator.translate('links.headerLong'),
        }, translator);

        const blogShown = config.links.blog && !config.links.splitBlog;
        const { data: posts } = useAsyncData(async () => {
            if (!blogShown) {
                return [];
            }
            return await $fetch('/api/blog');
        }, {
            lazy: true,
        });

        return {
            config,
            blogShown,
            posts,
        };
    },
});
</script>
